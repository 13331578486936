import './App.css';

function App() {
  return (
    <div className="App">
      <h1 className='text-red-500'>Need WiFi???</h1>

      <h1>Cox Panoramic WiFi 7 now available!</h1>

      <p>
        <u>CONTACT</u>
      </p>
      <p>Rex Robertson</p>
      <p>619-453-9674</p>
      <p>rex.robertson@cox.com</p>
      <p>Your Cox Property Representative</p>
      <p>San Diego</p>
    </div>
  );
}

export default App;
